import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <p>&copy; {new Date().getFullYear()} ApexThoughts. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="https://twitter.com/apexthoughts" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">Twitter</a>
            <a href="https://www.linkedin.com/company/apexthoughts" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">LinkedIn</a>
            <a href="https://github.com/apexthoughts" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">GitHub</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;