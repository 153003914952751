import React from 'react';

function Services() {
  const services = [
    { name: "Artificial Intelligence Solutions", icon: "🤖" },
    { name: "Blockchain Development", icon: "🔗" },
    { name: "Internet of Things (IoT) Integration", icon: "📡" },
    { name: "Sustainable Technology Consulting", icon: "🌿" },
    { name: "Custom Software Development", icon: "💻" }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="text-4xl mb-4">{service.icon}</div>
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">{service.name}</h2>
            <p className="text-gray-700">We provide cutting-edge solutions in {service.name.toLowerCase()} to help businesses thrive in the digital age.</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;