import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="container mx-auto px-4 py-12">
      <section className="text-center mb-16">
        <h1 className="text-5xl md:text-6xl font-bold mb-4 text-gray-800">Welcome to ApexThoughts</h1>
        <p className="text-xl md:text-2xl text-gray-600">Innovating for a Better Tomorrow</p>
      </section>

      <section className="mb-16 flex flex-col md:flex-row items-center bg-gray-100 p-6 md:p-8 rounded-lg shadow-md">
        <div className="w-full md:w-1/2 md:pr-8 mb-6 md:mb-0">
          <h2 className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800">Our Mission</h2>
          <p className="text-base md:text-lg text-gray-700 leading-relaxed">
            At ApexThoughts, we harness cutting-edge technologies to create inclusive benefits for human society. 
            Our innovative solutions address global challenges and pave the way for a brighter, more sustainable future.
          </p>
          <Link to="/about" className="inline-block mt-6 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300">
            Learn More
          </Link>
        </div>
        <div className="w-full md:w-1/2">
          <img src="https://via.placeholder.com/500x300" alt="Our Mission" className="w-full rounded-lg shadow-md" />
        </div>
      </section>

      <section className="mb-16">
        <h2 className="text-3xl md:text-4xl font-semibold mb-8 text-center text-gray-800">What We Offer</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { title: "Innovative Solutions", description: "We develop groundbreaking technologies that solve real-world problems.", icon: "🚀", image: "https://via.placeholder.com/300x200" },
            { title: "Inclusive Approach", description: "Our innovations are designed to benefit all segments of society, ensuring no one is left behind.", icon: "🤝", image: "https://via.placeholder.com/300x200" },
            { title: "Sustainable Future", description: "We prioritize sustainability in all our projects and initiatives, focusing on long-term impact.", icon: "🌱", image: "https://via.placeholder.com/300x200" }
          ].map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105">
              <img src={item.image} alt={item.title} className="w-full h-40 object-cover mb-4 rounded" />
              <div className="text-4xl mb-4">{item.icon}</div>
              <h3 className="text-xl md:text-2xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-sm md:text-base text-gray-700">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center bg-gray-100 p-8 rounded-lg shadow-md">
        <h2 className="text-3xl md:text-4xl font-semibold mb-6 text-gray-800">Ready to Innovate?</h2>
        <p className="text-lg md:text-xl text-gray-600 mb-8">Join us in shaping the future with cutting-edge technology and sustainable solutions.</p>
        <Link to="/contact" className="inline-block bg-green-600 text-white py-3 px-6 rounded-lg text-lg hover:bg-green-700 transition duration-300">
          Get Started
        </Link>
      </section>
    </div>
  );
}

export default Home;