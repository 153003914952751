import React from 'react';

function Projects() {
  const projects = [
    { name: "Smart City Infrastructure", description: "Developing intelligent systems for urban management and sustainability." },
    { name: "Renewable Energy Management System", description: "Optimizing energy distribution and consumption through AI and IoT." },
    { name: "AI-Powered Healthcare Diagnostics", description: "Leveraging machine learning for early disease detection and personalized treatment plans." },
    { name: "Blockchain-based Supply Chain Solution", description: "Enhancing transparency and efficiency in global supply chains." }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">Our Projects</h1>
      <p className="text-xl text-gray-700 mb-8 text-center">
        Discover our recent innovative projects that are shaping the future:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {projects.map((project, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <h2 className="text-2xl font-semibold mb-3 text-gray-800">{project.name}</h2>
            <p className="text-gray-700">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;