import React from 'react';

function Contact() {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">Contact Us</h1>
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-8">
          <p className="text-xl text-gray-700 mb-6">
            Get in touch with us for any inquiries or collaboration opportunities:
          </p>
          <ul className="space-y-4">
            {[
              { icon: "✉️", text: "Email: info@apexthoughts.com" },
              { icon: "📞", text: "Phone: +1 (555) 123-4567" },
              { icon: "🏢", text: "Address: 123 Innovation Street, Tech City, TC 12345" }
            ].map((item, index) => (
              <li key={index} className="flex items-center text-lg text-gray-700">
                <span className="text-2xl mr-4">{item.icon}</span>
                {item.text}
              </li>
            ))}
          </ul>
        </div>
        <div className="bg-gray-100 p-8">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Send us a message</h2>
          <form className="space-y-4">
            <input type="text" placeholder="Your Name" className="w-full p-2 border rounded" />
            <input type="email" placeholder="Your Email" className="w-full p-2 border rounded" />
            <textarea placeholder="Your Message" rows="4" className="w-full p-2 border rounded"></textarea>
            <button type="submit" className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;