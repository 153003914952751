import React from 'react';

function About() {
  const teamMembers = [
    { name: "Ray C", role: "CEO & Co-Founder", image: "https://i.pravatar.cc/150?img=1" },
    { name: "YZ Huang", role: "COO & Co-Founder", image: "https://i.pravatar.cc/150?img=2" },
    { name: "Emily Johnson", role: "Head of Innovation", image: "https://i.pravatar.cc/150?img=3" },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-5xl font-bold mb-8 text-gray-800 text-center">About ApexThoughts</h1>
      
      <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
        <h2 className="text-3xl font-semibold mb-4 text-gray-800">Our Story</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Founded in 2015, ApexThoughts emerged from a vision to revolutionize the tech industry by creating solutions that not only push the boundaries of innovation but also address pressing global challenges. Our journey began with a small team of passionate technologists and has since grown into a diverse community of forward-thinkers, all united by a common goal: to harness the power of technology for the greater good.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed">
          Today, ApexThoughts stands at the forefront of technological advancement, continuously striving to develop cutting-edge solutions that make a tangible difference in people's lives and contribute to a more sustainable, equitable future.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-3 text-gray-800">Our Vision</h2>
          <p className="text-gray-700">To be the catalyst for positive global change through innovative technology, inspiring a future where cutting-edge solutions empower communities, protect our planet, and enhance the quality of life for all.</p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-3 text-gray-800">Our Values</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>Relentless Innovation</li>
            <li>Ethical Integrity</li>
            <li>Collaborative Synergy</li>
            <li>Environmental Stewardship</li>
            <li>Inclusive Progress</li>
          </ul>
        </div>
      </div>

      <div className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800 text-center">Our Leadership</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
              <img src={member.image} alt={member.name} className="w-32 h-32 rounded-full mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-blue-600 text-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold mb-4">Join Our Journey</h2>
        <p className="text-lg mb-6">
          At ApexThoughts, we're always looking for passionate individuals who share our vision and want to make a difference. Whether you're a seasoned professional or a fresh graduate, there might be a place for you in our team.
        </p>
        <button className="bg-white text-blue-600 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
          View Career Opportunities
        </button>
      </div>
    </div>
  );
}

export default About;